<template>
	<div>
		<van-steps :active="step_active" active-icon="success" active-color="#ff976a">
			<van-step>{{translate('withdraw_option')}}</van-step>
			<van-step>{{translate('withdraw_rsswithdrawal_amount')}}</van-step>
			<van-step>{{translate('withdraw_accounts_receivable')}}</van-step>
			<van-step>{{translate('withdraw_moderated')}}</van-step>
		</van-steps>

		<h4 class="h4_title">{{translate('withdraw_account_balance')}}</h4>
		
		<van-cell-group>
			<van-cell :title="translate('withdraw_amount_that_can_be_withdrawn')" :value="balance_show" />
		</van-cell-group>

		<h4 class="h4_title">{{translate('withdraw_rsswithdrawal_amount')}}</h4>

		<van-cell-group>
			<van-cell :title="translate('withdraws_rsswithdrawal_amount')" :value="formItem.symbol + formItem.total" />
			<van-cell :title="translate('withdraw_handling_fee')" :value="service_fee" v-if="service_fee != 0" />
			<van-cell :title="translate('withdraw_total')" :value="amount" v-if="amount != 0" />
		</van-cell-group>
		
		<p style="color: #666;font-size: 12px;margin: 10px 16px" v-if="withdraw_fee_type == 2">{{translate('fee_per_transaction')}}：{{ withdraw_fee }}%</p>
		<p style="color: #666;font-size: 12px;margin: 10px 16px" v-if="withdraw_fee_type == 3">{{translate('fee_per_transaction')}}：{{ this.formItem.symbol }} {{ withdraw_fee }}</p>

		<h4 class="h4_title">{{translate('withdraws_accounts_receivable')}}</h4>		

		<van-form @submit="next" ref="formItem">
			<!-- <van-field name="checkbox" label="其他" v-if="formItem.c == 2">
				<template #input>
					<van-checkbox v-model="formItem.is_usdt" shape="square" @change="alterIsUsdt" />
				</template>
			</van-field>

			<template v-if="formItem.is_usdt == 0"> -->
			<template>
				<van-field
					readonly
					clickable
					:label="translate('withdraw_account_type')"
					:value="types[indexType] != undefined ? types[indexType].text : ''"
					:placeholder="translate('please_select_account_type')"
					@click="showType = true"
					:rules="accountRule.account"
					required
				/>
				<van-popup v-model="showType" round position="bottom">
					<van-picker
						:title="translate('withdraw_account_type')"
						show-toolbar
						:columns="types"
						:default-index="indexType"
						@cancel="showType = false"
						@confirm="onConfirmType"
					/>
				</van-popup>
			</template>
			<div v-if="formItem.bank_type == 4">
				<van-field 
					v-model="speedpay.name" 
					label="账户名" 
					required
					disabled
				/>
				<van-field 
					v-model="speedpay.chinese_name" 
					label="账户中文名" 
					required
					disabled
				/>
				<van-field 
					v-model="speedpay.card_name" 
					label="账户类型" 
					required
					disabled
				/>
				<van-field 
					v-model="speedpay.card_no" 
					label="speedpay卡号" 
					required
					disabled
				/>
				
				
				<van-field 
					v-model="speedpay.mobile" 
					label="手机号" 
					required
					disabled
				/>
				<van-field 
					v-model="speedpay.address" 
					label="地址" 
					required
					disabled
				/>

			</div>
			<div v-if="formItem.bank_type == 1 || formItem.bank_type == 2">
				<van-field 
					v-model="hk.country" 
					:label="translate('withdraw_nation')" 
					:placeholder="translate('withdraw_please_enter')"
					:rules="hkRules.country"
					required
				/>
				
				<van-field 
					v-model="hk.bank" 
					:label="translate('withdraw_bank')" 
					:placeholder="translate('withdraw_please_enter')"
					:rules="hkRules.bank"
					required
				/>

				<van-field 
					v-model="hk.bank_code" 
					:label="translate('bank_code')" 
					:placeholder="translate('bank_please_enter')"
					:rules="hkRules.bank_code"
					required
				/>

				<van-field 
					v-model="hk.bank_address" 
					:label="translate('withdraw_bank_address')" 
					:placeholder="translate('withdraw_please_enter')"
					:rules="hkRules.bank_address"
					required
				/>
				<van-field 
					v-model="hk.account_name" 
					:label="translate('withdraw_account_name')" 
					:placeholder="translate('withdraw_please_enter')"
					:rules="hkRules.account_name"
					required
				/>
				<van-field 
					v-model="hk.account_no" 
					:label="translate('withdraw_bank_card_number')" 
					:placeholder="translate('withdraw_please_enter')"
					:rules="hkRules.account_no"
					required
				/>
				<van-field
					v-model="hk.account_address" 
					:label="translate('withdraw_account_address')" 
					:placeholder="translate('withdraw_please_enter')"
					:rules="hkRules.account_address"
					required
				/>

				<van-field
					v-model="hk.swift_code"
					label="SWIFT CODE"
					:placeholder="translate('withdraw_please_enter')"
					:rules="hkRules.swift_code"
					required
				/>
			</div>
			
			<div v-if="formItem.bank_type == 3">
				<van-field 
					v-model="usdt.address" 
					:label="translate('withdraw_banks_address')" 
					:placeholder="translate('withdraw_please_enter')"
					required
					:rules="usdtRules.address"
					/>

				<van-field v-model="usdt.usdt_image" required :rules="usdtRules.usdt_image" :label="translate('withdraw_qrcode_picture')">
					<template #input>
						<van-image 
							width="100" 
							height="100" 
							:src="image_preview" 
							style="vertical-align: middle;" 
							@click="qrcodeUpload()"/>
					</template>
				</van-field>
			</div>

			<template v-if="formItem.bank_type == 1000">
				<van-field 
					v-model="usdt.address" 
					label="账户" 
					:placeholder="translate('withdraw_please_enter')"
					required
					type="textarea"
					rows="2"
					:rules="usdtRules.address"
					/>

				<van-field v-model="usdt.usdt_image" required :rules="usdtRules.usdt_image" label="图片">
					<template #input>
						<van-image 
							width="100" 
							height="100" 
							:src="image_preview" 
							style="vertical-align: middle;" 
							@click="qrcodeUpload()"/>
					</template>
				</van-field>
			</template>

			<div class="common_block">
				<van-button type="warning" block :disabled="myti" native-type="submit">{{translate('withdraw_confirm_submission')}}</van-button>
			</div>

		</van-form>


		<div class="common_block">
			<p style="text-align: right;color: blue;" @click="goHistory">{{translate('withdraw_rsswithdrawal_record')}}</p>
		</div>

		<div class="description" v-html="tip" style="padding: 0 15px 10px 15px"></div>
	</div>
</template>

<script>
	export default {
		name: 'rsswithdraw_type',
		data() {
			return {
				speedpay:{},
				myti:false,
				myti1:false,
				myti2:false,
				step_active: 2,

				resourceURL: this.$axios.defaults.resourceURL,

				formItem: {
					market: 'HK',
					c: 0,
					symbol: "",
					total: "",
					token: "",
					mps:"",
					withdraw_id: 0,
					is_usdt: 0,
					bank_type: "",
					bank_info: "",
				},

				balance: 0,
				balance_show: 0,
				available_show: 0,

				amount: 0,
				service_fee: 0,
				withdraw_fee: 0,
				withdraw_fee_type: 0,

				showType: false,
				types: [],
				indexType: -1,
				image_preview: this.$axios.defaults.resourceURL + "images/img_upload.png",
				tip:"",

				accountRule: {
					account: [
						{
							required: true,
							message: this.translate('please_select_account_type'),
							trigger: 'onBlur'
						}
					],
				},

				/*		USDT验证规则		*/
				usdt: {
					address: "",
					type: "1",
					type_text: "",
					usdt_image: ""
				},

				usdtRules: {
					
					address: [
						{
							required: true,
							message: '请填写',//this.translate('withdraw_please_fill_in_the_address'),
							trigger: 'onBlur'
						}
					],
					usdt_image: [
						{
							required: true,
							message: '请上传图片',//this.translate('withdraw_please_upload_qrcode_picture'),
							trigger: 'onBlur'
						}
					]
				},
				/*		USDT验证规则		*/

				/*		海外银行卡验证规则		*/
				hk: {
					name: "",
					country: "",
					bank: "",
					bank_code: "",
					bank_address: "",
					account_name: "",
					account_no: "",
					account_address: "",
					swift_code: "",
				},

				hkRules: {
					country: [
						{
							required: true,
							message: this.translate('withdraw_please_fill_in_the_country'),
							trigger: 'onBlur'
						}
					],
					bank: [
						{
							required: true,
							message: this.translate('withdraw_please_fill_in_the_bank'),
							trigger: 'onBlur'
						}
					],
					bank_code: [
						{
							required: true,
							message: this.translate('bank_please_fill_in_the_bank_code'),
							trigger: 'onBlur'
						}
					],
					bank_address: [
						{
							required: true,
							message: this.translate('withdraw_please_fill_in_the_bank_address'),
							trigger: 'onBlur'
						}
					],
					account_name: [
						{
							required: true,
							message: this.translate('withdraw_please_fill_in_the_account_name'),
							trigger: 'onBlur'
						}
					],
					account_no: [
						{
							required: true,
							message: this.translate('withdraw_please_fill_in_the_bank_number'),
							trigger: 'onBlur'
						}
					],
					account_address: [
						{
							required: true,
							message: this.translate('withdraw_please_fill_account_address'),
							trigger: 'onBlur'
						}
					],
					swift_code: [
						{
							required: true,
							message: this.translate('withdraw_please_fill_in_the_swift_code'),
							trigger: 'onBlur'
						}
					],
				},
				/*		海外银行卡验证规则		*/
			}
		},
		mounted() {
			if (this.$route.query.token == "" || this.$route.query.token == undefined) {
				this.$router.replace('/nologin')
			} else {
				if (window.sessionStorage.getItem('c') == undefined 
					|| window.sessionStorage.getItem('c_name') == undefined
						|| window.sessionStorage.getItem('total') == undefined
							|| window.sessionStorage.getItem('symbol') == undefined) {
					this.$router.replace('/rsswithdraw/index?token=' + this.$route.query.token)

					return false
				}

				this.formItem.c = window.sessionStorage.getItem('c')
				this.formItem.total = window.sessionStorage.getItem('total')
				this.formItem.symbol = window.sessionStorage.getItem('symbol')
				this.formItem.token = this.$route.query.token
				this.formItem.mps = this.$route.query.mps

				this.balance = window.sessionStorage.getItem('balance')
				this.balance_show = this.formItem.symbol + this.balance

				// if (window.sessionStorage.getItem('c_name') == 'USDT') {
				// 	this.formItem.bank_type = 3
				// } else {
				// 	this.formItem.bank_type = 2
				// }

				this.$axios({
					method: 'post',
					url: 'rss_withdraw/methods', 
					data: {
						token: this.formItem.token,
						currency_id: this.formItem.c,
						pay_password: this.formItem.mps
					},
				}).then ((res) => {
					if (res.success) {
						this.types = res.data

						this.loadData()
					} else {
						this.$dialog.alert({
							message: res.error_text
						})
					}
				})

				window.set_qrcode_pic = this.set_qrcode_pic
			}
		},
		methods: {
			loadData() {
				this.$axios({
					method: 'post',
					url: 'rss_withdraw/index', 
					data: {
						token: this.formItem.token
					}   
				}).then ((res) => {
					if (res.success) {
						switch (res.data.bank_type) {
							case 2:
								this.hk.name = res.data.bank_info_origin.name
								this.hk.country = res.data.bank_info_origin.country
								this.hk.bank = res.data.bank_info_origin.bank
								this.hk.bank_code = res.data.bank_info_origin.bank_code
								this.hk.bank_address = res.data.bank_info_origin.bank_address
								this.hk.account_name = res.data.bank_info_origin.account_name
								this.hk.account_no = res.data.bank_info_origin.account_no
								this.hk.account_address = res.data.bank_info_origin.account_address
								this.hk.swift_code = res.data.bank_info_origin.swift_code

								break

							case 3:

								this.usdt.type_text = res.data.bank_info_origin.type_text
								this.usdt.address = res.data.bank_info_origin.address
								this.usdt.type = res.data.bank_info_origin.type

								for (var i in this.types) {
									if (this.types[i]['type'] == this.usdt.type) {

										this.indexType = i

										this.onConfirmType(this.types[i], i)

										break
									}
								}

								break
						}

					}
				})
			},
			goHistory() {
				this.$router.push('/rsswithdraw/history?token=' + this.formItem.token)
			},
			qrcodeUpload() {
				if (window.JsObject != null) {
					window.JsObject.usdtQRImageUpload()
				}
				
				if (window.webkit != null) {
					window.webkit.messageHandlers.jsToOcWithPrams.postMessage({"camera": "normal"})
				}
			},
			set_qrcode_pic(path) {
				this.usdt.usdt_image = path

				this.$axios({
					method: 'post',
					url: 'rss_withdraw/resize', 
					data: {
						token: this.formItem.token,
						image: path
					},
				}).then ((res) => {
					if (res.success) {
						this.image_preview = res.data.preview
					} else {
						this.$dialog.alert({
							message: res.error_text
						})
					}
				})
			},
			onConfirmType(value, index) {
				this.formItem.bank_type=value.bank_type
				if(value.bank_type == 4){
					this.getSpeedpayCard()
				}
				this.indexType = index

				this.tip = value.tip

				this.formItem.withdraw_id = value.type

				this.usdt.type = value.type
				this.usdt.type_text = value.text

				this.showType = false

				this.withdraw_fee = value.withdraw_fee
				this.withdraw_fee_type = value.withdraw_fee_type

				if (this.withdraw_fee_type != 1) {
					value = parseFloat(this.formItem.total)

					if (this.withdraw_fee_type == 2) {
						//	按比例
						this.service_fee = value * this.withdraw_fee / 100
						this.service_fee = this.service_fee.toFixed(3)

						this.amount = value + value * this.withdraw_fee / 100

					} else if (this.withdraw_fee_type == 3) {
						//	固定收费
						this.service_fee = this.withdraw_fee
						this.amount = value + this.withdraw_fee
					}

					this.amount = this.amount.toFixed(3)
				} else {
					if (this.withdraw_fee_type == 3) {
						this.service_fee = this.withdraw_fee
					} else {
						this.service_fee = 0
					}

					this.amount = 0
				}
			},
			next() {
				var balance = parseFloat(this.balance)

				if (this.formItem.bank_type == 3 && parseFloat(this.formItem.total) < 50) {
					this.$dialog.alert({
						message: this.translate('withdraw_minimum_usdt_rsswithdrawal_is')
					})
					
					return false
				}

				if(this.formItem.bank_type == 1000 && parseFloat(this.formItem.total) < 50) {
					this.$dialog.alert({
						message: "出金金额不能低于50"
					})
					
					return false
				}

				if (this.withdraw_fee_type != 1) {
					if (this.amount > balance) {
						this.$dialog.alert({
							message: this.translate('withdraw_insufficient_account_balance') + this.balance_show
						})
						
						return false
					}
				} else {
					if (parseFloat(this.formItem.total) > balance) {
						this.$dialog.alert({
							message: this.translate('withdraw_insufficient_account_balance') + this.balance_show
						})
						
						return false
					}
				}

				this.$dialog.confirm({
					message: this.translate('withdraw_please_confirm_receiving_address')
				}).then(() => {
	
					switch (this.formItem.bank_type) {

						case '1':

							this.formItem.bank_info = JSON.stringify(this.hk)
							
							// this.formItem.bank_info = JSON.stringify({
							// 	'姓名'			: this.hk.name,
							// 	'国家'			: this.hk.country,
							// 	'银行'			: this.hk.bank,
							// 	'银行地址'		: this.hk.bank_address,
							// 	'账户名'		: this.hk.account_name,
							// 	'银行卡号'  	: this.hk.account_no,
							// 	'账户地址'  	: this.hk.account_address,
							// 	'SWIFT CODE'	: this.hk.swift_code
							// })

							break;
						case '2':
						this.formItem.bank_info = JSON.stringify(this.hk)
							break;
						case '4':
							this.formItem.bank_info = JSON.stringify(this.speedpay)

						break;
						case '1000':
								var usdt = {
									is_usdt: 1,
									account: this.usdt.address,
									image: this.usdt.usdt_image,
								}
								this.formItem.bank_info = JSON.stringify(usdt)

							break;

						case '3':

						this.formItem.bank_info = JSON.stringify(this.usdt)

							// this.formItem.bank_info = JSON.stringify({
							// 	'主网类型'		: this.usdt.type_text,
							// 	'地址'			: this.usdt.address,
							// 	'图片'			: this.usdt.usdt_image
							// })

							break
						}
					this.myti = true;
					let time=setTimeout(()=>{
						this.myti1=true;
						if(this.myti2){
							this.myti1=false;
							this.myti2=false;
							this.myti = false;
						}
						clearTimeout(time)
					},3000);
					this.$axios({
						method: 'post',
						url: 'rss_withdraw/insert', 
						data: this.formItem
					}).then ((res) => {
						this.myti2=true;
						if(this.myti1){
							this.myti1=false;
							this.myti2=false;
							this.myti = false;
						}
						this.$toast.clear()
						
						if (res.success) {
							this.$toast.success({
								message:this.translate('withdraw_rsswithdrawal_success_submitted')
							})

							window.sessionStorage.removeItem('c')
							window.sessionStorage.removeItem('symbol')
							window.sessionStorage.removeItem('total')
							window.sessionStorage.removeItem('c_name')
							window.sessionStorage.removeItem('balance')

							this.$router.replace('/rsswithdraw/history?token=' + this.formItem.token)
						} else {
							this.$dialog.alert({
								message: res.error_text
							})
						}
					})
				}).catch(() => {
					return 0
				})
			},
			getSpeedpayCard(){
				this.$axios({
					method: 'post',
					url: 'cash_withdraw/getSpeedpayCard', 
					data:{
						token:this.formItem.token
					}
				}).then ((res) => {
					if (res.success) {
						this.speedpay=res.data;
					}else{
						if(res.error_code==1000){
							this.$router.push('/speedpay/index?token='+this.$route.query.token)
						}else{
							this.$dialog.alert({
								message: res.error_text
							})
						}
					}
				})
			}
		},
	}
</script>
